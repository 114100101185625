const SercomHelper = {
  DATABASE_VERSION: 2,
  PLATFORM: 3, // Web

  DEFAULT_PAGE_SIZE: 15,

  BORDER_RADIUS_SMALL: '5px',
  BORDER_RADIUS_MEDIUM: '8px',
  BORDER_RADIUS_LARGE: '20px',

  GOOGLE_CLIENT_ID: '641981710258-d36bepce8pcpc7obhce1dejqd6mm8nrh.apps.googleusercontent.com',

  stringToDecimal: (value: string) => {
    let decimal = Number.parseFloat(value);
    if (isNaN(decimal) || decimal < 0) decimal = 0;
    return decimal;
  },
  displayDecimal: (value?: number) => {
    return value ? value.toFixed(2).toString() : '0.00';
  },
  displayFormattedAmountWithCurrency(value: number /* , currency?: string */) {
    return `$${SercomHelper.displayDecimal(value)}`;
  },
  getFormattedNumber: (value: number) => {
    const formattedNumber = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);

    return formattedNumber;
  },
  getLanguageCode: (code: string): string => {
    const languages: Record<string, string> = {
      'fr': 'fr-CA',
      'fr-FR': 'fr-CA',
      'fr-CA': 'fr-CA',
      'en-US': 'en-US',
    };

    return languages[code] || languages['en-US'];
  },
};

export default SercomHelper;

