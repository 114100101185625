import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SercomTypes } from '../../support/sercom-types';
import SercomDialog from '../../components/SercomDialog';
import SercomTextField from '../../components/SercomTextField';
import SercomEnums from '../../support/sercom-enums';
import { useTranslation } from 'react-i18next';
import snackbarHelper from '../../helpers/snackbar-helper';
import { AdminService } from '../../services';
import moment from 'moment';
import dayjs from 'dayjs';
import SercomSelect from '../../components/SercomSelect';
import SercomNumericField from '../../components/SercomNumericField';
import SercomConstants from '../../support/sercom-constants';
import SercomDatePicker from '../../components/SercomDatePicker';

const AccountPopup = (props: SercomTypes.SercomDialog) => {
  const subscriptionPlans: SercomTypes.SercomItem[] = [
    { value: SercomEnums.SubscriptionPlan.Solo, label: 'Solo' },
    { value: SercomEnums.SubscriptionPlan.Squad, label: 'Squad' },
    { value: SercomEnums.SubscriptionPlan.Enterprise, label: 'Enterprise' },
  ];

  const languages: SercomTypes.SercomItem[] = [
    { value: SercomEnums.Language.English, label: 'English' },
    { value: SercomEnums.Language.French, label: 'Français' },
  ];

  const [account, setAccount] = useState<SercomTypes.Account>({
    subscriptionPlan: SercomEnums.SubscriptionPlan.Squad,
    language: SercomEnums.Language.French,
    expirationDate: moment().utc().add(SercomConstants.DEFAULT_TRIAL_DAYS, 'days').toDate(),
    maxMembers: 5,
  });
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [companyNameError, setCompanyNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [maxUsersError, setMaxUsersError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function init() {}

    init();
  }, []);

  const onFirstNameChange = (event: any) => {
    setAccount({ ...account, firstName: event.target.value });
  };
  const onLastNameChange = (event: any) => {
    setAccount({ ...account, lastName: event.target.value });
  };
  const onEmailChange = (event: any) => {
    setAccount({ ...account, email: event.target.value });
  };
  const onPasswordChange = (event: any) => {
    setAccount({ ...account, password: event.target.value });
  };
  const onCompanyNameChange = (event: any) => {
    setAccount({ ...account, companyName: event.target.value });
  };
  const onSubscriptionPlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccount({ ...account, subscriptionPlan: parseInt(event.target.value) });
  };
  const onMaxMembersChange = (event: any) => {
    setAccount({ ...account, maxMembers: parseInt(event.target.value) });
  };
  const onExpirationDateChange = (value: dayjs.Dayjs) => {
    setAccount({ ...account, expirationDate: value.toDate() });
  };

  const validate = (account: SercomTypes.Account): SercomEnums.ErrorCode => {
    setFirstNameError(!account.firstName);
    setLastNameError(!account.lastName);
    setEmailError(!account.email);
    setPasswordError(!account.password);
    setCompanyNameError(!account.companyName);
    setMaxUsersError(!account.maxMembers || account.maxMembers <= 0 || account.maxMembers > 30);

    if (!account.firstName) {
      return SercomEnums.ErrorCode.InvalidFirstName;
    }
    if (!account.lastName) {
      return SercomEnums.ErrorCode.InvalidLastName;
    }
    if (!account.email) {
      return SercomEnums.ErrorCode.InvalidEmail;
    }
    if (!account.companyName) {
      return SercomEnums.ErrorCode.InvalidName;
    }
    if (!account.maxMembers || account.maxMembers <= 0 || account.maxMembers > 30) {
      return SercomEnums.ErrorCode.InvalidName;
    }
    return SercomEnums.ErrorCode.None;
  };

  const onSave = async () => {
    const errorCode: number = validate(account);
    if (errorCode != SercomEnums.ErrorCode.None) {
      snackbarHelper.showErrorFromCode(errorCode, t);
      return;
    }
    const result = await AdminService.createAccount(account);
    if (result.isSuccess && result.data) {
      if (result.data.errorCode && result.data.errorCode != SercomEnums.ErrorCode.None) {
        snackbarHelper.showErrorFromCode(result.data.errorCode, t);
      } else {
        // setAccount(result.data[0]);
        props.action();
        props.onClose();
      }
    } else {
      snackbarHelper.showErrorFromCode(SercomEnums.ErrorCode.ServerError, t);
    }
  };

  return (
    <SercomDialog title={t('CreateAccount')} isOpen={props.isOpen} onClose={props.onClose} buttonText={props.buttonText} action={onSave}>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h4">{t('UserInfo')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <SercomTextField
            title={t('FirstName')}
            isRequired
            error={firstNameError}
            value={account.firstName ?? ''}
            onChange={onFirstNameChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SercomTextField
            title={t('LastName')}
            isRequired
            error={lastNameError}
            value={account.lastName ?? ''}
            onChange={onLastNameChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SercomTextField title={t('Email')} isRequired error={emailError} value={account.email ?? ''} onChange={onEmailChange} />
        </Grid>
        <Grid item xs={6}>
          <SercomTextField
            title={t('Password')}
            isRequired
            error={passwordError}
            value={account.password ?? ''}
            onChange={onPasswordChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SercomSelect title={t('Language')} items={languages} selectedItem={account?.language} onChange={onSubscriptionPlanChange} />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ pt: 3 }}>
            {t('CompanyInfo')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <SercomTextField
            title={t('CompanyName')}
            isRequired
            error={companyNameError}
            value={account.companyName ?? ''}
            onChange={onCompanyNameChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SercomSelect
            title={t('Plan')}
            items={subscriptionPlans}
            selectedItem={account?.subscriptionPlan}
            onChange={onSubscriptionPlanChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SercomNumericField
            // name={taxDetail.id}
            title={t('MaxUsers')}
            isRequired
            error={maxUsersError}
            decimalScale={0}
            value={account.maxMembers}
            onChange={onMaxMembersChange}
          ></SercomNumericField>
        </Grid>
        <Grid item xs={6}>
          <SercomDatePicker title={t('ExpirationDate')} value={dayjs(account.expirationDate)} onChange={onExpirationDateChange} />
        </Grid>
      </Grid>
    </SercomDialog>
  );
};

export default AccountPopup;

