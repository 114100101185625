import APIRequestHelper from '../helpers/api-request-helper';
import { SercomTypes } from '../support/sercom-types';

const AdminService = {
  async getCompaniesDatasource() {
    return APIRequestHelper.getDataManager('POST', 'admin/companies');
  },
  async getLeadsDatasource() {
    return APIRequestHelper.getDataManager('POST', 'admin/leads');
  },
  async createAccount(account: SercomTypes.Account) {
    const payload = {
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email,
      password: account.password,
      companyName: account.companyName,
      subscriptionPlan: account.subscriptionPlan,
      language: account.language,
      expirationDate: account.expirationDate,
      maxMembers: account.maxMembers,
      affiliateId: account.affiliateId,
    };
    return await APIRequestHelper.sendRequest('POST', 'admin/create_account', false, payload);
  },

  async closeCompany(companyId: string) {
    return APIRequestHelper.sendRequest('POST', `admin/close_company?companyId=${companyId}`, false);
  },
  async getSubscription(companyId: string) {
    return await APIRequestHelper.sendRequest('GET', `admin/get_subscription/${companyId}`, false, null);
  },
  async getKpis() {
    return await APIRequestHelper.sendRequest('GET', 'admin/get_admin_kpis', false, null);
  },
  async updateSubscription(subscription: SercomTypes.Subscription) {
    const payload = {
      companyId: subscription.companyId,
      plan: subscription.plan,
      maxMembersCount: subscription.maxMembersCount,
      expirationDate: subscription.expirationDate,
      isTrial: subscription.isTrial,
      optimizer: subscription.optimizer,
      timesheet: subscription.timesheet,
      communication: subscription.communication,
      payment: subscription.payment,
      affiliateId: subscription.affiliateId,
    };
    return await APIRequestHelper.sendRequest('POST', 'admin/update_subscription', false, payload);
  },
};
export default AdminService;

