import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { SercomTypes } from '../support/sercom-types';
import { v4 as uuid } from 'uuid';

const SercomSelect = (props: SercomTypes.SercomSelect) => {
  const guid = uuid();
  const title = props.title.concat(props.isRequired ? '*' : '');
  return (
    <FormControl fullWidth>
      {!props.isStandard && (
        <InputLabel id={guid} sx={{ top: '-4px' }}>
          {title}
        </InputLabel>
      )}
      <Select
        name={props.name ?? undefined}
        fullWidth
        labelId={guid}
        value={props.selectedItem}
        error={props.error}
        label={props.title}
        disabled={props.disabled ?? false}
        size="small"
        variant={props.isStandard ? 'standard' : 'outlined'}
        onChange={props.onChange}
        sx={{ display: 'flex', verticalAlign: 'middle', minWidth: 120 }}
      >
        {props.items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SercomSelect;

