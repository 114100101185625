import { Grid, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SercomTypes } from '../../support/sercom-types';
import SercomDialog from '../../components/SercomDialog';
import SercomEnums from '../../support/sercom-enums';
import { useTranslation } from 'react-i18next';
import snackbarHelper from '../../helpers/snackbar-helper';
import { AdminService, UserService } from '../../services';
import moment from 'moment';
import dayjs from 'dayjs';
import SercomSelect from '../../components/SercomSelect';
import SercomNumericField from '../../components/SercomNumericField';
import SercomConstants from '../../support/sercom-constants';
import SercomDatePicker from '../../components/SercomDatePicker';
import SercomSwitch from '../../components/SercomSwitch';

const SubscriptionPopup = (props: SercomTypes.SercomDialog) => {
  const subscriptionPlans: SercomTypes.SercomItem[] = [
    { value: SercomEnums.SubscriptionPlan.Solo, label: 'Solo' },
    { value: SercomEnums.SubscriptionPlan.Squad, label: 'Squad' },
    { value: SercomEnums.SubscriptionPlan.Enterprise, label: 'Enterprise' },
  ];

  const [subscription, setSubscription] = useState<SercomTypes.Subscription>({
    plan: SercomEnums.SubscriptionPlan.Squad,
    frequency: SercomEnums.SubscriptionFrequency.Month,
    membersCount: 1,
    maxMembersCount: 5,
    subscriptionAutoRenew: true,
    expirationDate: moment().utc().add(SercomConstants.DEFAULT_TRIAL_DAYS, 'days').toDate(),
    price: 0,
    isTrial: true,
    optimizer: false,
    communication: false,
    payment: false,
    timesheet: false,
  });
  const [affiliates, setAffiliates] = useState<SercomTypes.Affiliate[]>([{ id: SercomConstants.NONE_SELECT_VALUE, name: 'None' }]);
  const [maxUsersError, setMaxUsersError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function init() {
      if (props.id) {
        const result = await AdminService.getSubscription(props.id);
        if (result.isSuccess) {
          setSubscription(result.data);
        }
      }
      const affiliates = await UserService.getAffiliates();
      console.log('affiliates:', affiliates);
      if (affiliates.isSuccess && affiliates.data) {
        setAffiliates([{ id: SercomConstants.NONE_SELECT_VALUE, name: 'None' }].concat(affiliates.data));
      }
    }

    init();
  }, []);

  const onSubscriptionPlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubscription({ ...subscription, plan: parseInt(event.target.value) });
  };
  const onMaxMembersChange = (event: any) => {
    setSubscription({ ...subscription, maxMembersCount: parseInt(event.target.value) });
  };
  const onExpirationDateChange = (value: dayjs.Dayjs) => {
    setSubscription({ ...subscription, expirationDate: value.toDate() });
  };
  const onAffiliateChange = (event: SelectChangeEvent) => {
    setSubscription({
      ...subscription,
      affiliateId: event.target.value == SercomConstants.NONE_SELECT_VALUE ? undefined : event.target.value,
    });
  };
  const onOptimizerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubscription({ ...subscription, optimizer: event.target.checked });
  };
  const onTimesheetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubscription({ ...subscription, timesheet: event.target.checked });
  };
  // const onCommunicationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSubscription({ ...subscription, communication: event.target.checked });
  // };
  const onPaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubscription({ ...subscription, payment: event.target.checked });
  };
  const onTrialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubscription({ ...subscription, isTrial: event.target.checked });
  };

  const validate = (subscription: SercomTypes.Subscription): SercomEnums.ErrorCode => {
    setMaxUsersError(!subscription.maxMembersCount || subscription.maxMembersCount <= 0 || subscription.maxMembersCount > 30);

    if (!subscription.maxMembersCount || subscription.maxMembersCount <= 0 || subscription.maxMembersCount > 30) {
      return SercomEnums.ErrorCode.MaximumMembersReached;
    }
    return SercomEnums.ErrorCode.None;
  };

  const onSave = async () => {
    const errorCode: number = validate(subscription);
    if (errorCode != SercomEnums.ErrorCode.None) {
      snackbarHelper.showErrorFromCode(errorCode, t);
      return;
    }
    const result = await AdminService.updateSubscription(subscription);
    if (result.isSuccess && result.data) {
      if (result.data.errorCode && result.data.errorCode != SercomEnums.ErrorCode.None) {
        snackbarHelper.showErrorFromCode(result.data.errorCode, t);
      } else {
        snackbarHelper.showSuccess(t('SubscriptionUpdatedSuccessfully'));
        props.action();
        props.onClose();
      }
    } else {
      snackbarHelper.showErrorFromCode(SercomEnums.ErrorCode.ServerError, t);
    }
  };

  return (
    <SercomDialog
      title={t('UpdateSubscription')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      buttonText={props.buttonText}
      action={onSave}
    >
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <SercomSelect title={t('Plan')} items={subscriptionPlans} selectedItem={subscription?.plan} onChange={onSubscriptionPlanChange} />
        </Grid>
        <Grid item xs={6}>
          <SercomNumericField
            title={t('MaxUsers')}
            isRequired
            error={maxUsersError}
            decimalScale={0}
            value={subscription.maxMembersCount}
            onChange={onMaxMembersChange}
          ></SercomNumericField>
        </Grid>
        <Grid item xs={6}>
          <SercomDatePicker title={t('ExpirationDate')} value={dayjs(subscription.expirationDate)} onChange={onExpirationDateChange} />
        </Grid>
        <Grid item xs={6}>
          <SercomSelect
            title={t('Affiliate')}
            items={affiliates.map((element) => ({
              value: element.id ?? '--',
              label: element.name ?? '--',
            }))}
            selectedItem={subscription?.affiliateId ?? SercomConstants.NONE_SELECT_VALUE}
            onChange={onAffiliateChange}
          ></SercomSelect>
        </Grid>

        {/* <Grid item xs={6} /> */}
        <Grid item xs={6}>
          <SercomSwitch label={t('Trial')} checked={subscription?.isTrial ?? true} onChange={onTrialChange} />
        </Grid>
        {/* <Grid item xs={6} /> */}
        <Grid item xs={6}>
          <SercomSwitch label={t('Optimizer')} checked={subscription?.optimizer ?? false} onChange={onOptimizerChange} />
        </Grid>
        <Grid item xs={6}>
          <SercomSwitch label={t('Timesheet')} checked={subscription?.timesheet ?? false} onChange={onTimesheetChange} />
        </Grid>
        {/* <Grid item xs={6}>
          <SercomSwitch label={t('Communication')} checked={subscription?.communication ?? false} onChange={onCommunicationChange} />
        </Grid> */}
        <Grid item xs={6}>
          <SercomSwitch label={t('Payment')} checked={subscription?.payment ?? false} onChange={onPaymentChange} />
        </Grid>
      </Grid>
    </SercomDialog>
  );
};

export default SubscriptionPopup;

