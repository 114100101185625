import styled, { createGlobalStyle } from 'styled-components';
import SercomConstants from './support/sercom-constants';

export const MainContainer = styled.div`
  width: 100%;
  padding: 20px;
  overflow: auto;
`;

export const SercomBaseStyles = createGlobalStyle`
  html,
  body,
  #m-root {
    height: 100%;
    overflow: hidden;
  }

  body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: ${SercomConstants.Color.TYPEFACE_6};
  }

  .n-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .n-bottom-spacing {
    margin-bottom: 20px;
  }

  .MuiListItem-root .MuiListItemButton-root.Mui-selected,
  .MuiListItem-root .MuiListItemButton-root:hover {
    background-color:${SercomConstants.Color.HOVERMENU} ;
    border-radius: 4px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: ${SercomConstants.BORDER_RADIUS};
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background: ${SercomConstants.Color.SURFACE_4};
    border-radius: ${SercomConstants.BORDER_RADIUS};
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background: ${SercomConstants.Color.SURFACE_4};
    border-radius: ${SercomConstants.BORDER_RADIUS};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #bdbdbd;
  }
`;

export const SyncfusionStyles = createGlobalStyle`
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: unset;
  }

  .e-input-group:not(.e-filled) .e-input-group-icon::after,
  .e-input-group.e-control-wrapper:not(.e-filled) .e-input-group-icon::after {
    display: none;
  }

  .e-date-range-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
    color: ${SercomConstants.Color.TYPEFACE_4};
  }

  .e-dropdownbase .e-list-item.e-active,
  .e-dropdownbase .e-list-item.e-active.e-hover {
    color: ${SercomConstants.Color.PRIMARY};
  }

  .e-input-group:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
    border-bottom: unset;
  }

  .e-ddl.e-popup .e-content .e-list-item[data-value='-1'] {
    color: ${SercomConstants.Color.PRIMARY};
  }

  .e-spinner-pane .e-spinner-inner .e-spin-material {
    stroke: ${SercomConstants.Color.PRIMARY};
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    border-color: unset;
  }

  .e-footer-content .e-btn.e-flat.e-primary {
      color: ${SercomConstants.Color.PRIMARYBUTTON};
      background: ${SercomConstants.Color.PRIMARYMAIN};

      &:hover {
        background: ${SercomConstants.Color.PRIMARYDARK};
      }
  }
  .e-toolbar .e-toolbar-item .e-tbar-btn.e-tbtn-txt {
    height: 32px;
    padding: 0 8px;    
    border-radius: 5px;
    /*border: 1px solid transparent;*/
    &:hover {
      /*border-color: ${SercomConstants.Color.PRIMARYMAIN};*/
      background: rgba(73, 85, 121, 0.04);
    }
  }
  div:has(.e-control.e-grid) {
    width: 100%!important;
  }
  .e-grid.e-default .e-headercell {
    background: ${SercomConstants.Color.SECONDARYLIGHT};
  }
  .e-grid th.e-headercell .e-headertext {
    color: ${SercomConstants.Color.PRIMARYMAIN};
  }
  .e-grid .e-row .e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd) {
    color: ${SercomConstants.Color.PRIMARYMAIN};
  }
  .e-grid .e-gridheader {
    border: none;
  }
  .e-grid .e-gridheader .e-columnheader .e-headercell {
    border:none;
  }
  .e-grid.e-resize-lines .e-headercell .e-rhandler, 
  .e-grid.e-resize-lines .e-headercell .e-rsuppress {
    border-right: none;
  }
  .e-grid .e-gridheader tr:first-child th {
    border-top-left-radius: 3px;
  }
  .e-grid .e-gridheader tr:last-child th {
    border-top-right-radius: 3px;
  }
`;

