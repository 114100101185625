namespace SercomEnums {
  // xs: pxToRem(1.6),
  // sm: pxToRem(2),
  // md: pxToRem(6),
  // lg: pxToRem(8),
  // xl: pxToRem(12),
  // xxl: pxToRem(16),
  // section: pxToRem(160),

  export enum HttpAction {
    Get = 0,
    Post = 1,
    Put = 2,
    Patch = 3,
    Delete = 4,
  }
  export enum ErrorCode {
    None = 0,
    ServerError = 1,
    NotAllowed = 2,
    AlreadyExists = 3,
    DoesntExist = 4,
    InvalidParameter = 5,
    MissingCompany = 6,
    MissingUser = 7,
    MissingMember = 8,
    MissingClient = 9,
    MissingJob = 10,
    MissingQuote = 11,
    NoteTooLong = 12,
    InvalidName = 13,
    InvalidCode = 14,
    InvalidEmail = 15,
    InvalidUrl = 16,
    InvalidFirstName = 17,
    InvalidLastName = 18,
    Expired = 19,
    InvalidRate = 20,
    TaxDefinedManyTimes = 21,
    InvalidTax = 22,
    MaxRowsExceeded = 23,
    InvalidFile = 24,
    InvalidFrequency = 25,
    DeleteSelf = 26,
    AddressRequired = 27,
    EmptyClientEmail = 28,
    MaximumMembersReached = 29,
    MissingQuickBooksIntegration = 30,
    JobAlreadyPushedQuickBooks = 31,
    QuoteAlreadyPushedQuickBooks = 32,
    JobDetailRequired = 33,
    QuoteDetailRequired = 34,
    MissingTax = 35,
    NoTaxRate = 36,
    RefreshTokenExpired = 37,
    MissingIntuitSignature = 38,
    InvalidQuickBooksData = 39,
    MissingRequest = 40,
    EmptyNote = 41,
    InvalidPeriod = 42,
  }
  export enum LoginMode {
    LimitedView = 0,
    AdminView = 1,
    BothViews = 2,
    CreateCompany = 3,
  }
  export enum DisplayMode {
    Light = 0,
    Dark = 1,
    System = 2,
  }
  export enum RequestStatus {
    Open = 0,
    Converted = 1,
    Closed = 2,
  }
  export enum QuoteStatus {
    Draft = 0,
    Sent = 1,
    Approved = 2,
    Converted = 3,
    Closed = 4,
  }
  export enum JobStatus {
    Active = 0,
    Completed = 1,
    Converted = 2,
    Closed = 3,
  }
  export enum ClientType {
    Company = 0,
    Individual = 1,
  }
  export enum ClientStatus {
    Active = 0,
    Inactive = 1,
  }
  export enum ItemStatus {
    Active = 0,
    Inactive = 1,
  }
  export enum TaxStatus {
    Active = 0,
    Inactive = 1,
  }
  export enum MemberStatus {
    Active = 0,
    Pending = 1,
    Inactive = 2,
  }
  export enum ItemType {
    Product = 0,
    Service = 1,
  }
  export enum EventSource {
    Request = 0,
    Quote = 1,
    Job = 2,
    All = 3,
  }
  export enum FollowupSource {
    Request = 0,
    Quote = 1,
  }
  export enum SubscriptionPlan {
    Solo = 0,
    Squad = 1,
    Enterprise = 2,
  }
  export enum LeadCategory {
    Plumbing = 0,
    Electricity = 1,
    Paint = 2,
    HeatingAirConditioning = 3,
    ChimneySweeping = 4,
    WaterTreatment = 5,
    Gutter = 6,
    DoorWindow = 7,
    Flooring = 8,
    GarageDoor = 9,
    Exterminator = 10,
  }
  export enum LeadStatus {
    Enabled = 0,
    Disabled = 1,
    Converted = 2,
  }
  export enum LeadEmail {
    FirstContact = 0,
    Followup1 = 1,
    Followup2 = 2,
  }
  // export enum AdminKpi {
  //   Mrr = 0,
  //   Arr = 1,
  //   ClientCount = 2,
  // }
  export enum SubscriptionFrequency {
    Month = 0,
    Year = 1,
  }
  export enum ImportType {
    Lead = 0,
  }
  export enum BorderRadius {
    XSMALL = 1,
    SMALL = 2,
    MEDIUM = 6,
    LARGE = 8,
    XLARGE = 12,
    XXLARGE = 16,
  }
  export enum Language {
    English = 0,
    French = 1,
  }
  export enum DateFormat {
    ISO = 0,
    USDash = 1,
    USSlash = 2,
    InternationalDash = 3,
    InternationalSlash = 4,
    InternationalDots = 5,
  }
  export enum TimeFormat {
    H12 = 0,
    H24 = 1,
  }
  export enum DistanceUnit {
    Km = 0,
    Mile = 1,
  }
  export enum FirstDayWeek {
    Sunday = 0,
    Monday = 1,
  }
  export enum RepeatPeriod {
    Day = 0,
    Week = 1,
    Month = 2,
    Year = 3,
  }
  export enum RepeatEndType {
    None = 0,
    Date = 1,
    Count = 2,
  }
  export enum MemberPermission {
    Admin = 0,
    Manager = 1,
    Employee = 2,
  }
  export enum ExternalType {
    Invitation = 0,
    SendQuote = 1,
    NewPassword = 2,
  }
  export enum ReminderType {
    HourBefore = 0,
    DayBefore = 1,
  }
  export enum DashboardType {
    OpenRequests = 0,
    UnconvertedQuotes = 1,
    UnconvertedJobs = 2,
    ScheduledJobs = 3,
    CompletedJobs = 4,
    AverageJobs = 5,
    HourPerEmployee = 6,
  }
  export enum GridCustomizationType {
    None = 0,
    Request = 1,
    Quote = 2,
    Job = 3,
    Client = 4,
    Product = 5,
    Service = 6,
    Tax = 7,
    Member = 8,
    Company = 9,
    ClientRequest = 10,
    ClientQuote = 11,
    ClientJob = 12,
    ClientContact = 13,
    Form = 14,
    Invoice = 15,
    ClientInvoice = 16,
    Task = 17,
    ClientTask = 18,
    JobTask = 19,
    AdminLead = 20,
  }
  export enum SettingsMenu {
    Company = 0,
    Member = 1,
    Service = 2,
    Product = 3,
    Invoicing = 4,
    Integration = 5,
  }
  export enum LoadingFormat {
    Circle,
    Rectangle,
  }
}

export default SercomEnums;

