import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
// import { render } from 'react-dom';
import { DataManager } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {
  Aggregate,
  AggregateColumnDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  GridComponent,
  Inject,
  Page,
  Search,
  ToolbarItems,
  Filter,
  Sort,
  FilterSettingsModel,
  ColumnChooser,
  Toolbar,
  ExcelExport,
  // PdfExport,
  Resize,
  PageSettingsModel,
  ColumnsDirective,
  ColumnDirective,
  ColumnModel,
  Reorder,
  SearchSettingsModel,
  RecordClickEventArgs,
  SortSettingsModel,
} from '@syncfusion/ej2-react-grids';
import '@syncfusion/ej2-react-grids/styles/material.css';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import { StyledGrid } from './SercomGrid.styled';
import { renderRowLoadingState } from './rowLoadingState';
import { getDateColumnFilter } from './dateColumnFilter';
import { getEnumColumnFilter } from './enumColumnFilter';
import SercomEnum from '../../support/sercom-enums';
import { SercomTypes } from '../../support/sercom-types';
import DefaultNoDataTemplate from './DefaultNoDataTemplate';
import { GridCustomizationService } from '../../services';
import { t } from 'i18next';
import SercomHelper from '../../support/sercom-helper';
import i18n from '../../i18n';
import { createRoot } from 'react-dom/client';

type GridColumn = ColumnModel & { allowSearch?: boolean; enumFilter?: { label: string; value: any }[] };

type ToolbarItem = { id?: string } | ToolbarItems;

type SercomColumn = {
  field: string;
  index: number;
  width: number;
  visible: boolean;
};

type SercomData = {
  columns?: SercomColumn[];
  filterSettings?: FilterSettingsModel;
  toolbar?: ToolbarItem[];
  pageSettings?: PageSettingsModel;
  searchSettings?: SearchSettingsModel;
  sortSettings?: SortSettingsModel;
};

type GridProps = {
  dataSource: DataManager | object[];
  columns: GridColumn[];
  filterSettings?: FilterSettingsModel;
  toolbar?: ToolbarItem[];
  pageSettings?: PageSettingsModel;
  searchSettings?: SearchSettingsModel;
  sortSettings?: SortSettingsModel;
  allowPaging?: boolean;
  allowSorting?: boolean;
  allowFiltering?: boolean;
  allowResizing?: boolean;
  allowExcelExport?: boolean;
  // allowPdfExport?: boolean;
  allowReordering?: boolean;
  showToolbar?: boolean;
  recordClick?: (args: RecordClickEventArgs) => void;
  noDataTemplate?: JSX.Element;
  noDataImage?: string;
  noDataTitle?: string;
  allowSelection?: boolean;
  noDataText?: string;
  gridCustomizationType?: SercomEnum.GridCustomizationType;
  gridRef?: MutableRefObject<GridComponent | null>;
  aggregateColumns?: SercomTypes.GridAggregate[];
};

const DEFAULT_COLUMN_WIDTH = 100;
const MIN_COLUMN_WIDTH = 50;

const defaultFilterSettings: FilterSettingsModel = {
  type: 'Menu',
  ignoreAccent: true,
};

const defaultPageSettings: PageSettingsModel = { pageSize: 10, pageSizes: [10, 15, 20, 50] };

const defaultSearchSettings: SearchSettingsModel = { ignoreCase: true, ignoreAccent: true, operator: 'contains' };

const defaultSortSettings = { columns: [] };

const defaultToolbar: (object | ToolbarItems)[] = [
  { text: t('ClearFilters'), id: 's-grid-clear-filters', align: 'Right' },
  { text: t('ResetLayout'), id: 's-grid-reset-customization', align: 'Right' },
  { text: t('ExportExcel'), id: 's-grid-export-excel', align: 'Right', prefixIcon: 's-grid-export-excel-icon' },
  // { text: 'Export to Pdf', id: 's-grid-export-pdf', align: 'Right', prefixIcon: 's-grid-export-pdf-icon' },
  'ColumnChooser',
  'Search',
];

// Syncfusion Grid needs its container to have an explicit width to properly handle horizontal scrolling
// when the columns does not fit in the grid display, else it overflows the page or there is no scroll bar
const updateGridContainerWidth = (gridContainerElement: HTMLElement | null, gridElement?: HTMLElement) => {
  if (!gridContainerElement) {
    return;
  }

  let originalGridElementStyleDisplay: string = '';

  gridContainerElement.style.width = '';

  if (gridElement) {
    originalGridElementStyleDisplay = gridElement.style.display;
    gridElement.style.display = 'none';
  }

  const gridContainerParentWidth = gridContainerElement.parentElement?.clientWidth as number;
  const gridContainerStyles = getComputedStyle(gridContainerElement.parentElement as HTMLDivElement);
  const newGridContainerWidth =
    gridContainerParentWidth - parseInt(gridContainerStyles.paddingLeft) - parseInt(gridContainerStyles.paddingRight);

  gridContainerElement.style.width = `${newGridContainerWidth}px`;

  if (gridElement) {
    gridElement.style.display = originalGridElementStyleDisplay;
  }
};

const getDateColumnHeaderTemplate = (columnData: any) =>
  `<div class="n-ellipsis n-date-column-header-${columnData.field}">
    ${columnData.headerText}
    <span class="n-date-column-header-date"></span>
  </div>`;

function mapSyncfusionDataToSercomData(syncfusionData: GridComponent | null) {
  const sercomData: SercomData = {};
  sercomData.columns = syncfusionData?.columns?.map((column: any, index) => ({
    field: column.field,
    index,
    type: column.type,
    width: column.width,
    enumFilter: column.enumFilter,
    visible: column.visible,
  }));
  sercomData.filterSettings = {
    columns: syncfusionData?.filterSettings?.columns?.map((column: any) => ({
      field: column.field,
      operator: column.operator,
      predicate: column.predicate,
      value: column.value,
      isEndDateRange: column.isEndDateRange,
    })),
  };

  sercomData.pageSettings = {
    pageSize: syncfusionData?.pageSettings?.pageSize,
  };

  sercomData.sortSettings = {
    columns: syncfusionData?.sortSettings?.columns?.map((column) => ({ field: column.field, direction: column.direction })),
  };

  return sercomData;
}

const SercomGrid = (props: GridProps) => {
  const {
    dataSource,
    columns,
    toolbar = defaultToolbar,
    filterSettings = defaultFilterSettings,
    pageSettings = defaultPageSettings,
    searchSettings = defaultSearchSettings,
    sortSettings = defaultSortSettings,
    allowPaging = true,
    allowSorting = true,
    allowFiltering = true,
    allowResizing = true,
    allowExcelExport = true,
    // allowPdfExport = true,
    allowReordering = true,
    showToolbar = true,
    recordClick,
    noDataTemplate = DefaultNoDataTemplate,
    gridCustomizationType = SercomEnum.GridCustomizationType.None,
    // noDataImage,
    // noDataTitle = 'No data',
    // noDataText,
    gridRef = useRef<GridComponent | null>(null),
    aggregateColumns,
  } = props;

  const [gridCustomizationLoaded, setGridCustomizationLoaded] = useState<boolean>(false);
  const [persistedProps, setPersistedProps] = useState<any>({});
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const gridCustomizationData = useRef<SercomData | null>(null);
  const dateFields = useRef<any>({});

  useEffect(() => {
    async function initializeGrid() {
      const gridCustomization = await GridCustomizationService.getGridCustomization(gridCustomizationType);

      if (gridCustomization?.value) {
        gridCustomizationData.current = gridCustomization.value;
      }

      setPersistedProps(mapSercomDataToSyncfusion());
      setGridCustomizationLoaded(true);
      showToolbarItemResetLayout(gridCustomizationData?.current != null);
      showToolbarItemClearFilters(
        gridCustomizationData?.current?.filterSettings?.columns?.length != null &&
          gridCustomizationData?.current?.filterSettings?.columns?.length > 0
      );
    }

    const updateGridContainerWidthOnResize = () => {
      updateGridContainerWidth(gridContainerRef.current, gridRef.current?.element);
    };

    updateGridContainerWidthOnResize();
    window.addEventListener('resize', updateGridContainerWidthOnResize);

    initializeGrid();

    return () => {
      window.removeEventListener('resize', updateGridContainerWidthOnResize);
    };
  }, []);

  const getSearchFields = (gridColumns: any) => {
    if (gridColumns) {
      return gridColumns
        .filter((column: any) => (column?.allowSearch ?? false) && (column?.visible ?? true))
        .map((column: any) => column.field);
    }
  };

  const onToolbarClick = (args: ClickEventArgs) => {
    if (args.item.id === 's-grid-export-excel') {
      gridRef.current?.excelExport();
    }

    // if (args.item.id === 's-grid-export-pdf') {
    //   gridRef.current?.pdfExport();
    // }

    if (args.item.id === 's-grid-reset-customization') {
      resetGridCustomization();
    }

    if (args.item.id === 's-grid-clear-filters') {
      resetGridFilters();
    }
  };

  const getLocalPersistedProps = () => {
    const local: any = {};

    local.columns = columns
      .filter((column: any) => column?.isAccessible ?? true)
      .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
      .map((column) => {
        const columnWidth = column.width ?? DEFAULT_COLUMN_WIDTH;
        const columnData = { minWidth: MIN_COLUMN_WIDTH, width: columnWidth, ...column };

        if (column.type === 'string' && !column.filter) {
          columnData.filter = { operator: 'contains' };
        }

        if (column.enumFilter) {
          columnData.filter = getEnumColumnFilter(column.enumFilter);
        }

        if (column.type === 'date') {
          columnData.filter = getDateColumnFilter(gridRef, dateFields);
          columnData.headerTemplate = getDateColumnHeaderTemplate(columnData);
        }

        return columnData;
      });

    local.filterSettings = {
      ignoreAccent: true,
      type: 'Menu',
      ...filterSettings,
      columns: [...(filterSettings?.columns ?? [])],
    };

    local.pageSettings = {
      pageSize: 10,
      pageSizes: [10, 15, 20, 50],
      ...pageSettings,
    };

    local.searchSettings = {
      ignoreCase: true,
      operator: 'contains',
      ...searchSettings,
      fields: getSearchFields(local.columns),
    };

    local.sortSettings = {
      columns: [],
      ...sortSettings,
    };

    return local;
  };

  const mapSercomDataToSyncfusion = () => {
    const localPersistedProps = getLocalPersistedProps();
    const customization = gridCustomizationData?.current;

    if (customization) {
      const mappedPersistedProps: any = {};

      mappedPersistedProps.columns = localPersistedProps.columns
        .map((column: any) => {
          const persistedColumnData = customization?.columns?.find((serverColumn) => serverColumn.field === column.field);
          const columnData = { ...column };

          if (persistedColumnData) {
            columnData.index = persistedColumnData.index;
            columnData.visible = persistedColumnData.visible;
            columnData.width = persistedColumnData.width;
          }

          return columnData;
        })
        .sort((a: any, b: any) => a.index - b.index); // Re-order the columns, required for correct display

      mappedPersistedProps.filterSettings = {
        ...localPersistedProps.filterSettings,
      };

      customization?.filterSettings?.columns?.forEach((column: any) => {
        const columnIndex = mappedPersistedProps.filterSettings.columns.findIndex(
          (filterColumn: any) => filterColumn.field === column.field && filterColumn.operator === column.operator
        );

        const columnIsNotAvailableForFiltering = mappedPersistedProps.columns.some(
          (gridColumn: any) => gridColumn.field === column.field && !(gridColumn.visible ?? true)
        );
        if (columnIsNotAvailableForFiltering) {
          return;
        }

        if (columnIndex > -1) {
          const currentColumn = mappedPersistedProps.filterSettings.columns[columnIndex];
          if (currentColumn.value instanceof Date) {
            mappedPersistedProps.filterSettings.columns = mappedPersistedProps.filterSettings.columns.map((filterColumn: any) => {
              if (filterColumn.field !== column.field) {
                return filterColumn;
              }
              return { ...filterColumn, value: filterColumn.isEndDateRange ? new Date(column?.value[1]) : new Date(column.value[0]) };
            });
          } else {
            mappedPersistedProps.filterSettings.columns[columnIndex] = column;
          }
        } else if (Array.isArray(column.value)) {
          const columnDataType = mappedPersistedProps.columns.find((x: any) => x.field === column.field)?.type;
          const isDateRange = columnDataType === 'date' && column.value?.length === 2;
          column.value.forEach((value: any, index: number) => {
            let { operator } = column;
            if (isDateRange) {
              if (index === 0) {
                operator = 'greaterthanorequal';
              } else {
                operator = 'lessthanorequal';
              }
            }
            mappedPersistedProps.filterSettings.columns.push({
              ...column,
              operator,
              value: columnDataType === 'date' ? new Date(value) : value,
            });
          });
        } else if (mappedPersistedProps.columns.find((x: any) => x.field === column.field)) {
          // Add the filter only if the columns exist
          mappedPersistedProps.filterSettings.columns.push(column);
        }
      });

      mappedPersistedProps.pageSettings = {
        ...localPersistedProps.pageSettings,
        ...customization.pageSettings,
      };

      // mappedPersistedProps.groupSettings = {
      //   ...localPersistedProps.groupSettings,
      //   // ...customization.groupSettings,
      // };

      mappedPersistedProps.searchSettings = {
        ...localPersistedProps.searchSettings,
        fields: getSearchFields(mappedPersistedProps.columns),
      };

      mappedPersistedProps.sortSettings = {
        ...localPersistedProps.sortSettings,
        ...customization.sortSettings,
      };

      return mappedPersistedProps;
    }

    return localPersistedProps;
  };

  const resetGridCustomization = async () => {
    const resetSuccess = await GridCustomizationService.resetGridCustomization(gridCustomizationType);
    if (resetSuccess) {
      updateGridProperties(getLocalPersistedProps());
      showToolbarItemResetLayout(false);
      showToolbarItemClearFilters(false);
    }
  };

  const resetGridFilters = async () => {
    updateGridProperties({ filterSettings: { columns: {} } });
    saveGridCustomization();
  };

  const showToolbarItemResetLayout = (show = false) => {
    const toolbarItemResetLayout = gridRef.current?.element?.querySelector('#s-grid-reset-customization')?.parentElement;

    if (!toolbarItemResetLayout) {
      return;
    }

    if (show) {
      toolbarItemResetLayout.classList.remove('n-hidden');
    } else {
      toolbarItemResetLayout.classList.add('n-hidden');
    }
  };

  const showToolbarItemClearFilters = (show: boolean) => {
    const toolbarItemClearFilters = gridRef.current?.element?.querySelector('#s-grid-clear-filters')?.parentElement;

    if (!toolbarItemClearFilters) {
      return;
    }

    if (show) {
      toolbarItemClearFilters.classList.remove('n-hidden');
    } else {
      toolbarItemClearFilters.classList.add('n-hidden');
    }
  };

  const saveGridCustomization = async () => {
    if (!gridCustomizationType) {
      return;
    }

    const gridCustomization = {
      type: gridCustomizationType,
      value: mapSyncfusionDataToSercomData(gridRef.current),
    };

    const responseData = await GridCustomizationService.saveGridCustomization(gridCustomization);
    showToolbarItemResetLayout(responseData != null);
    showToolbarItemClearFilters(
      responseData?.customization?.filterSettings?.columns != null && responseData?.customization?.filterSettings?.columns?.length !== 0
    );
  };

  const showLoadingPlaceholders = () => {
    const emptyRowElement = gridRef.current?.element?.querySelector('.e-emptyrow');

    if (emptyRowElement) {
      const numberOfRows = gridCustomizationData?.current?.pageSettings?.pageSize ?? (pageSettings.pageSize as number);
      const numberOfColumns =
        gridCustomizationData?.current?.columns?.filter((column) => column?.visible ?? true)?.length ??
        columns.filter((column) => column.visible ?? true).length;
      const emptyRowParentElement = emptyRowElement.parentElement;

      if (emptyRowParentElement) {
        renderRowLoadingState(emptyRowParentElement, numberOfRows, numberOfColumns);
      }
    }
  };

  const showNoData = (show: boolean) => {
    const gridContentElement = gridRef.current?.element?.querySelector('.e-gridcontent .e-content');

    if (!gridContentElement) {
      return;
    }

    if (show) {
      const root = gridRef.current?.element?.querySelector('.s-grid-no-data-root');
      if (!root) {
        const noDataRootElement = document.createElement('div');
        noDataRootElement.classList.add('s-grid-no-data-root');
        gridContentElement.prepend(noDataRootElement);
        const root = createRoot(noDataRootElement);
        root.render(noDataTemplate);
      }
      gridContentElement.classList.add('s-grid-has-no-data');
    } else if (gridContentElement) {
      gridContentElement.classList.remove('s-grid-has-no-data');
    }
  };

  const onCreated = () => {
    showLoadingPlaceholders();
  };

  const onDataBound = () => {
    if (gridRef.current?.currentViewData?.length) {
      showNoData(false);
    } else {
      showNoData(true);
    }
  };

  const updateGridProperties = (properties: any) => {
    const gridInstance = gridRef.current;

    if (gridInstance) {
      gridInstance.setProperties(properties, true);
      gridInstance.freezeRefresh();
      showLoadingPlaceholders();
    }
  };

  const onResizeStop = () => {
    saveGridCustomization();
  };

  const onActionBegin = (args: any) => {
    if (args.requestType !== 'filtering') {
      return;
    }

    if (args.action === 'clearFilter') {
      args.cancel = true;

      const filteredColumns = gridRef.current?.filterSettings?.columns?.filter(
        (column) => column.field !== args.currentFilterColumn?.field
      );

      updateGridProperties({ filterSettings: { columns: filteredColumns } });
    }

    if (args.action !== 'clearFilter') {
      const currentFilteringColumn = gridRef.current?.getColumnByField(args.currentFilteringColumn);

      if (currentFilteringColumn && currentFilteringColumn.type === 'date') {
        const endDateColumnIndex = args.columns.findIndex(
          (column: any) => column.field === args.currentFilteringColumn && column.isEndDateRange
        );

        if (endDateColumnIndex === -1) {
          args.columns.push({
            isEndDateRange: true,
            field: currentFilteringColumn.field,
            operator: 'lessthanorequal',
            predicate: 'and',
            value: dateFields.current[currentFilteringColumn.field]?.endDate,
          });
        } else {
          args.columns[endDateColumnIndex].value = dateFields.current[currentFilteringColumn.field]?.endDate;
        }
      }
    }
    saveGridCustomization();
  };

  const onActionComplete = (args: any) => {
    const isActionComplete = args.name === 'actionComplete' || args.type === 'actionComplete';

    if (
      !gridRef.current ||
      !isActionComplete ||
      (args.requestType !== 'columnstate' &&
        args.requestType !== 'reorder' &&
        args.requestType !== 'sorting' &&
        args.requestType !== 'paging')
    ) {
      return;
    }
    if (args.requestType === 'columnstate') {
      gridRef.current.searchSettings.fields = getSearchFields(gridRef.current.columns);
    }

    saveGridCustomization();
  };

  const services = [
    { module: Toolbar, validator: () => toolbar?.length > 0 ?? false },
    { module: ColumnChooser, validator: () => toolbar?.includes('ColumnChooser') ?? false },
    { module: Search, validator: () => toolbar?.includes('Search') ?? false },
    { module: Page, validator: () => allowPaging },
    { module: Sort, validator: () => allowSorting },
    { module: Reorder, validator: () => allowReordering },
    { module: Resize, validator: () => allowResizing },
    { module: Filter, validator: () => allowFiltering },
    {
      module: ExcelExport,
      validator: () => (allowExcelExport && toolbar?.find((item: any) => item?.id === 's-grid-export-excel') !== undefined) ?? false,
    },
    // {
    //   module: PdfExport,
    //   validator: () => (allowPdfExport && toolbar?.find((item: any) => item?.id === 's-grid-export-pdf') !== undefined) ?? false,
    // },
    { module: Aggregate, validator: () => aggregateColumns?.length ?? false },
  ];

  const injectedServices = services.filter((s) => s.validator()).map((s) => s.module);

  return (
    <div ref={gridContainerRef}>
      {gridCustomizationLoaded && (
        <StyledGrid
          ref={gridRef}
          locale={SercomHelper.getLanguageCode(i18n.language)}
          dataSource={dataSource}
          toolbar={showToolbar ? toolbar : null}
          actionBegin={onActionBegin}
          actionComplete={onActionComplete}
          allowPaging={allowPaging}
          allowFiltering={allowFiltering}
          allowSorting={allowSorting}
          allowExcelExport={allowExcelExport}
          // allowPdfExport={allowPdfExport}
          allowReordering={allowReordering}
          showColumnChooser={true}
          allowResizing={allowResizing}
          filterSettings={persistedProps.filterSettings}
          pageSettings={persistedProps.pageSettings}
          sortSettings={persistedProps.sortSettings}
          searchSettings={persistedProps.searchSettings}
          toolbarClick={onToolbarClick}
          recordClick={recordClick}
          created={onCreated}
          dataBound={onDataBound}
          // allowSelection={allowSelection}
          allowSelection={false}
          resizeStop={onResizeStop}
        >
          <ColumnsDirective>
            {persistedProps.columns.map((column: any, index: any) => (
              <ColumnDirective key={index} {...column} />
            ))}
          </ColumnsDirective>

          {aggregateColumns && aggregateColumns.length > 0 && (
            <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  {aggregateColumns.map((aggregateColumnProps) => (
                    <AggregateColumnDirective key={aggregateColumnProps.field} {...aggregateColumnProps} />
                  ))}
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
          )}
          <Inject services={injectedServices} />
        </StyledGrid>
      )}
    </div>
  );
};

export default SercomGrid;

